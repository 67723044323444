/* #### Generated By: http://font.download #### */

@font-face {
  font-family: 'Prompt';
  font-style: normal;
  font-weight: 400;
  src: local('Prompt Regular'), url('Prompt-Regular.woff') format('woff');
  }
  

  @font-face {
  font-family: 'Prompt';
  font-style: italic;
  font-weight: 400;
  src: local('Prompt Italic'), url('Prompt-Italic.woff') format('woff');
  }
  

  @font-face {
  font-family: 'Prompt';
  font-style: normal;
  font-weight: 100;
  src: local('Prompt Thin'), url('Prompt-Thin.woff') format('woff');
  }
  

  @font-face {
  font-family: 'Prompt';
  font-style: italic;
  font-weight: 100;
  src: local('Prompt Thin Italic'), url('Prompt-ThinItalic.woff') format('woff');
  }
  

  @font-face {
  font-family: 'Prompt';
  font-style: normal;
  font-weight: 200;
  src: local('Prompt ExtraLight'), url('Prompt-ExtraLight.woff') format('woff');
  }
  

  @font-face {
  font-family: 'Prompt';
  font-style: italic;
  font-weight: 200;
  src: local('Prompt ExtraLight Italic'), url('Prompt-ExtraLightItalic.woff') format('woff');
  }
  

  @font-face {
  font-family: 'Prompt';
  font-style: normal;
  font-weight: 300;
  src: local('Prompt Light'), url('Prompt-Light.woff') format('woff');
  }
  

  @font-face {
  font-family: 'Prompt';
  font-style: italic;
  font-weight: 300;
  src: local('Prompt Light Italic'), url('Prompt-LightItalic.woff') format('woff');
  }
  

  @font-face {
  font-family: 'Prompt';
  font-style: normal;
  font-weight: 500;
  src: local('Prompt Medium'), url('Prompt-Medium.woff') format('woff');
  }
  

  @font-face {
  font-family: 'Prompt';
  font-style: italic;
  font-weight: 500;
  src: local('Prompt Medium Italic'), url('Prompt-MediumItalic.woff') format('woff');
  }
  

  @font-face {
  font-family: 'Prompt';
  font-style: normal;
  font-weight: 600;
  src: local('Prompt SemiBold'), url('Prompt-SemiBold.woff') format('woff');
  }
  

  @font-face {
  font-family: 'Prompt';
  font-style: italic;
  font-weight: 600;
  src: local('Prompt SemiBold Italic'), url('Prompt-SemiBoldItalic.woff') format('woff');
  }
  

  @font-face {
  font-family: 'Prompt';
  font-style: normal;
  font-weight: 700;
  src: local('Prompt Bold'), url('Prompt-Bold.woff') format('woff');
  }
  

  @font-face {
  font-family: 'Prompt';
  font-style: italic;
  font-weight: 700;
  src: local('Prompt Bold Italic'), url('Prompt-BoldItalic.woff') format('woff');
  }
  

  @font-face {
  font-family: 'Prompt';
  font-style: normal;
  font-weight: 800;
  src: local('Prompt ExtraBold'), url('Prompt-ExtraBold.woff') format('woff');
  }
  

  @font-face {
  font-family: 'Prompt';
  font-style: italic;
  font-weight: 800;
  src: local('Prompt ExtraBold Italic'), url('Prompt-ExtraBoldItalic.woff') format('woff');
  }
  

  @font-face {
  font-family: 'Prompt';
  font-style: normal;
  font-weight: 900;
  src: local('Prompt Black'), url('Prompt-Black.woff') format('woff');
  }
  

  @font-face {
  font-family: 'Prompt';
  font-style: italic;
  font-weight: 900;
  src: local('Prompt Black Italic'), url('Prompt-BlackItalic.woff') format('woff');
  }