@font-face {
  font-family: 'Apple SD Gothic';
  font-weight: 200;
  src: url(../fonts/appleSD/AppleSDGothicNeoUL.ttf) format('truetype');
}
@font-face {
  font-family: 'Apple SD Gothic';
  font-weight: 300;
  src: url(../fonts/appleSD/AppleSDGothicNeoL.ttf) format('truetype');
}
@font-face {
  font-family: 'Apple SD Gothic';
  font-weight: 400;
  src: url(../fonts/appleSD/AppleSDGothicNeoR.ttf) format('truetype');
}
@font-face {
  font-family: 'Apple SD Gothic';
  font-weight: 500;
  src: url(../fonts/appleSD/AppleSDGothicNeoM.ttf) format('truetype');
}
@font-face {
  font-family: 'Apple SD Gothic';
  font-weight: 700;
  src: url(../fonts/appleSD/AppleSDGothicNeoB.ttf) format('truetype');
}
@font-face {
  font-family: 'Apple SD Gothic';
  font-weight: 800;
  src: url(../fonts/appleSD/AppleSDGothicNeoEB.ttf) format('truetype');
}